export const seo = {
  url: 'client/containers-dimensions',
  title: {
    pl: 'Wymiary Kontenerów',
    en: 'Containers Dimensions',
    ua: 'Розміри контейнера',
  },
  desc: {
    pl: `Kontenery, w zależności od rodzaju i zastosowania, różnią się parametrami. Dowiedz się więcej na temat dostępnych i najpopularniejszych wymiarów.`,
    en: `Containers, depending on their type and application, vary in parameters`,
    ua: `Containers, depending on their type and application, vary in parameters`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'kontenery', 'wymiary'],
}

export const intro = {
  title: {
    pl: 'Wymiary Kontenerów',
    en: 'Containers Dimensions',
    ua: 'Розміри контейнера',
  },
  desc: {
    pl: `Sprawdź różne typy kontenerów oraz ich szczegółowe wymiary.`,
    en: `Check container types and their specific dimensions.`,
    ua: `Перевірте типи контейнерів і їх конкретні розміри.`,
  },
}

export const main = {
  title: {
    pl: 'Kontenery, w zależności od rodzaju i zastosowania, różnią się parametrami',
    en: 'Containers, depending on their type and application, vary in parameters',
    ua: 'Контейнери, в залежності від типу і застосування, відрізняються за параметрами',
  },
  desc: {
    pl: `
    <p>
    Podstawową i bardzo ważną cechą każdego jest wielkość, którą określa się w stopach.
    W transporcie morskim stosuje się najczęściej kontenery 20- oraz 40-stopowe.
    </p>
    <p>
    Kolejnym z ważnych parametrów jest wymiar w drzwiach – określa on, jak wielki ładunek będziemy mogli zmieścić do kontenera.
    </p>
    <p>
    Zarówno szerokość, długość, jak i wysokość podawane są w milimetrach.
    </p>
    `,
    en: `
    <p>
    The basic and most important feature of each is the size, which is defined in feet.
    In maritime transport, 20- and 40-foot containers are most often used.
    </p>
    <p>
    Another important parameter is the door size - this determines how big a load will be able to fit into the container.
    </p>
    <p>
    Width, length and height are all given in millimetres.
    </p>
    `,
    ua: `
    <p>
    Основною і дуже важливою ознакою кожного є його розмір, який визначається у футах. 
    У морському транспорті найчастіше використовуються 20 і 40 футові контейнери.
    </p>
    <p>
    Ще один важливий параметр - розмір дверей - він визначає, скільки вантажу ми можемо вмістити в контейнер.
    </p>
    <p>
    І ширина, і довжина, і висота наведені в міліметрах.
    </p>
    `,
  },
  contarinerTypes: {
    pl: 'Poniżej przedstawiamy grafiki różnych typów kontenerów oraz ich szczegółowe wymiary',
    en: 'Container types and their specific dimensions can be seen on graphics below',
    ua: 'Нижче ми представляємо графіки різних типів контейнерів і їх детальні розміри',
  },
}
